<template>
  <form @submit.prevent="saveDetails">
    <card>
      <template slot="header">
        <p class="card-header-title">
          <strong>Settings</strong>
        </p>
      </template>

      <template slot="body">
        <b-field label="Name">
          <b-input v-model="form.name" placeholder="eg. Your Brand" required />
        </b-field>

        <b-field label="Email">
          <b-input
            v-model="form.email"
            placeholder="eg. support@yourbrand.com"
          />
        </b-field>

        <color-picker v-model="form.primaryColor" />

        <b-field label="Logo">
          <logo :reseller-id="resellerId" />
        </b-field>
      </template>

      <template slot="footer">
        <div class="is-stretched has-padding-75 has-text-right">
          <button
            :disabled="formIsDisabled"
            :class="{ 'is-loading': processing }"
            type="submit"
            class="button is-success"
          >
            Update
          </button>
        </div>
      </template>
    </card>
  </form>
</template>

<script>
import { doc, setDoc } from "@firebase/firestore";
export default {
  name: "ResellerDetails",
  components: {
    logo: () => import("@shared/reseller/_logo")
  },
  props: {
    resellerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {},
      formClone: {},
      processing: false
    };
  },
  computed: {
    reseller() {
      return this.$store.getters["reseller/reseller"](this.resellerId);
    },
    formIsValid() {
      if (!this.form.name) return false;
      return true;
    },
    hasChange() {
      return !this.$_.isEqual(this.form, this.formClone);
    },
    formIsDisabled() {
      return this.processing || !this.formIsValid || !this.hasChange;
    }
  },
  watch: {
    reseller: {
      handler() {
        this.$set(
          this,
          "form",
          this.$_.pick(this.reseller, ["primaryColor", "name", "email"])
        );
        this.$set(
          this,
          "formClone",
          this.$_.pick(this.reseller, ["primaryColor", "name", "email"])
        );
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    saveDetails() {
      if (this.formIsValid && !this.processing) {
        this.processing = true;
        const resellerRef = doc(this.$firestore, "resellers", this.resellerId);
        setDoc(resellerRef, this.form, {
          merge: true
        })
          .then(() => {
            this.$toast.open({
              message: "Reseller details updated",
              type: "is-success",
              position: "is-bottom",
              queue: false
            });
          })
          .catch(error => {
            this.$toast.open({
              message: error.message,
              type: "is-danger",
              position: "is-bottom",
              queue: false
            });
          })
          .finally(() => {
            this.processing = false;
          });
      }
    }
  }
};
</script>
